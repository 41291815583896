:root {
    --titulo: 'Oswald', sans-serif;
    --cuerpo: 'Barlow Condensed', sans-serif;
    --segundo: 'Oswald', sans-serif;
}

/* //TIPO DE LETRA

$cuerpo: 'Barlow Condensed', sans-serif;
$titulo: 'Oswald', sans-serif;
 */

$primary:  #282c34;
$dark-grey: #363636;
$light-grey: #d8d8d8;

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;
