@import "./base/base";
@import "./base/settings";

//COMPONENTS
@import "./components/auth";
@import "./components/google";
@import "./components/_spinner";
.final {
  margin-left: 1000px;
  margin-top: -40px;
}

.imagenLogo {
  height: 90px;
  width: 100px;
  margin: auto;
}

.imagenEstado {
  height: 60px;
  width: 60px;
  margin: auto;
  margin-top: 20px;
}

.celdasEditadas {
  font-size: 16px;
  padding-top: 38px !important;
}

.modal-container {
  background-color: white;
  margin: 6%;
  height: 94%;
  border-radius: 4px;
  padding-top: -10px;
}

@media (min-width: 768px) {
  .modal-container {
      height: 78%;
  }
}

.type {
  font-family: "Poppins", sans-serif;
}

.barra {
  background-color: #353535 !important;
  padding: 20px 0 !important;
}

//font-family: 'Poppins', sans-serif;
