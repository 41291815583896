html {
    box-sizing: border-box;
    height: 100%;
}
*, *:before, *:after {
    box-sizing: inherit;
}


body {
    font-family: var(--cuerpo);
    min-height: 100%;
}

.swal2-shown aside {
    height: 100vh;
}

.pointer {
    cursor: pointer;
}

