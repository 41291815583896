.auth__main{
    background-color: $primary;
}
/** Login **/
.login,
.crear-cuenta {
    background: $primary;
}
.contenedor-formulario {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-image: url("../../styles/img/bg.png");
}
.contenedor-formulario h1 {
    text-align: center;
    color: white;
    font-family: var(--titulo);
}
.contenedor-formulario h1  span{
    font-weight: 400;
    font-size: 1.2rem;
}
.caja-login {
    background-color: #F2F2F2;
    border: 1px solid #707070;
    max-width: 650px;
    width: 90%;
    margin: 0 auto;
    min-height: 300px;
    -webkit-box-shadow: 0px 25px 35px -17px rgba(0,0,0,0.56);
    -moz-box-shadow: 0px 25px 35px -17px rgba(0,0,0,0.56);
    box-shadow: 0px 25px 35px -17px rgba(0,0,0,0.56);
    padding: 3rem;
}
.campo {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
}
.campo label {
    flex: 0 0 100px;
    text-align: right;
    padding-right: 1rem;
}
.campo input[type="text"],
.campo input[type="password"] {
    height: 3rem;
    border: 0;
    flex: 1;
    border: 1px solid #455A64;
    border-radius: 5rem;
    padding-left: 2rem;
}
.enviar {
   justify-content: flex-end;
}
.boton {
    background-color: #FDD835;
    text-align: center;
    transition: background-color .5s ease-in-out;
    display: block;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 400;
    border: none;
    font-weight: 900;
}
.boton:hover {
    cursor: pointer;
    background-color: rgb(219, 184, 29)!important;
}
.campo a {
    color: black;
    text-decoration: none;
}

.auth__social-networks{
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
}

.auth__alert-error {
    background-color: red;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px
}